<template>
  <main class="bg-white">
    <section class="core">
      <div class="zfb-box mt5" v-if="type == 0">支付宝支付盒子</div>
      <div class="wx-box" v-if="type == 1">
        <div class="wx-info row">
          <div class="warn nr tips">
            订单：{{orderDetail.order_sn}} 提交成功，请尽快付款！ <br />
            <!-- 23时34分25秒 -->
            <div v-if="orderDetail.cancel_time">
              请您在<span class="price">{{orderDetail.cancel_time}}</span>内完成支付，否则订单会被自动取消
            </div>
          </div>
          <div class="payment">
            实付款：
            <make-priceformat :price="orderDetail.order_amount" :subscriptSize="22" :firstSize="32" :secondSize="32" color="#CD313B"
              weight="600"></make-priceformat>
          </div>
        </div>
        <div class="wx-code row">
          <div class="code-box flex1 column-center">
            <div class="title">微信支付</div>
            <img :src="wxImg" alt="" class="pay-code" />
            <div class="tips row-center black">
              <img src="@/assets/wx-pay.png" alt="" />
              <p>请使用微信扫一扫<br />扫描二维码支付</p>
            </div>
          </div>
          <div class="course-box flex1 row-center">
            <img src="@/temp/4.png" alt="" class="course-img" />
          </div>
        </div>
      </div>
      <div class="line-box" v-if="type == 2">
        <div class="title bg-primary white lg row-center">线下支付</div>
        <div class="line-content bg-white nr normal">
          <div class="payment">
            需付款：
            <make-priceformat :price="orderDetail.total_amount" :subscriptSize="22" :firstSize="32" :secondSize="32"
              color="#CD313B" weight="600"></make-priceformat>
          </div>
          <div class="flow-list">
            <div class="flow-item row" v-for="(item, index) in 3" :key="index">
              <div class="current row-center white">{{ index + 1 }}</div>
              <div class="item-text">
                <div class="text-title md mb10">登录你的网上银行</div>
                <div class="text-info xs">
                  必须使用本公司同名的银行账户进行汇款转账
                </div>
              </div>
            </div>
          </div>
          <div class="account-info pr xs normal mb20">
            <div class="info-title pa row-center">企业收款账户</div>
            <div class="info-list">
              <div class="info-item row">
                <div class="item-name">户名</div>
                <div class="item-content">{{orderDetail.dev_bank && orderDetail.dev_bank.acount_name}}</div>
                <div class="item-copy primary" @click="copy(orderDetail.dev_bank && orderDetail.dev_bank.acount_name)">复制</div>
              </div>
              <div class="info-item row">
                <div class="item-name">账号</div>
                <div class="item-content">{{orderDetail.dev_bank && orderDetail.dev_bank.account_num}}</div>
                <div class="item-copy primary" @click="copy(orderDetail.dev_bank && orderDetail.dev_bank.account_num)">复制</div>
              </div>
              <div class="info-item row">
                <div class="item-name">银行</div>
                <div class="item-content" ref="bank">
                  {{orderDetail.dev_bank && orderDetail.dev_bank.bank_name}}
                </div>
                <div class="item-copy primary" @click="copy(orderDetail.dev_bank && orderDetail.dev_bank.bank_name)">复制</div>
              </div>
              <div class="info-item row">
                <div class="item-name">负责人</div>
                <div class="item-content"> {{orderDetail.dev_bank && orderDetail.dev_bank.user_name}}</div>
                <div class="item-copy primary" @click="copy(orderDetail.dev_bank && orderDetail.dev_bank.user_name)">复制</div>
              </div>
              <div class="info-item row">
                <div class="item-name">联行号</div>
                <div class="item-content">{{orderDetail.dev_bank && orderDetail.dev_bank.bank_no}}</div>
                <div class="item-copy primary" @click="copy(orderDetail.dev_bank && orderDetail.dev_bank.bank_no)">复制</div>
              </div>
            </div>
          </div>
          <div class="tips-box">
            <div class="tips-title nr normal"><span class="price mr5">*</span>付款凭证</div>
            <div class="info xs warn">
              <el-form :model="ruleForm" ref="ruleForm" label-width="80px">
                <el-upload :action="`${download}/file/formimage`" :headers="{ token: $store.getters.userInfo.token }"
                  :on-success="successUpload" :on-exceed="handleExceed" :show-file-list="true" :limit="1"
                  :on-remove="handleRemove" >
                  <el-button size="small" type="primary" class="white">点击上传</el-button>
                  <!-- <button type="primary" class="white xs back" @click="$router.replace('/orderlist')">点我上传</button> -->
                  <!-- <el-image class="avatar" :src="ruleForm.avatar" fit="cover" v-if="ruleForm.avatar"></el-image>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
                </el-upload>


                <!-- <el-upload class="upload-demo" :action="`${download}/file/formimage`" 
                  :on-preview="handlePreview"  :before-remove="beforeRemove" multiple
                  :limit="3" :on-exceed="handleExceed" :file-list="fileList">
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload> -->
              </el-form>
            </div>
          </div>
          <div class="row-center">
            <button type="primary" style="margin-right:20px;" class="white md back" @click="subitPay()">确认已付款</button>
            <button type="primary" class="white md back" @click="$router.replace('/orderlist')">返回订单</button>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      type: 2,
      orderId: "",
      download: process.env.VUE_APP_DOWNLOAD_URL,
      userAvatar: '',
      ruleForm: {
        avatar: ''
      },
      orderDetail: {},

      wxImg:'',
      from:'',
      wxTimed:undefined,
    };
  },
  computed: {},
  watch: {},
  methods: {

    handleRemove(file, fileList) {
      this.userAvatar = ''
    },
    /**
     * @description 获取订单详情
     * @return void
     */
    getOrderDetail() {
      this.$api
        .getOrderDetailApi({
          id: this.orderId,
        })
        .then((res) => {
          this.orderDetail = res.data;
        });
    }, handleExceed(files, fileList) {
      this.$message.warning(`当前限制上传 1 个文件`);
    },

    successUpload(res) {
      let { code, data } = res;
      this.userAvatar = res.data.uri
      this.ruleForm.avatar = res.data.uri
    },

    subitPay() {
      if (this.userAvatar == '') {
        this.$message({
          message: "请上传付款凭证",
          type: "error",
        });
        return false;
      }
      this.$api
        .getXxPayApi({
          id: this.orderId,
          pay_img: this.userAvatar
        })
        .then((res) => {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.$router.push({ path: "/orderlist" });
        });
    },

    /**
     * @description 复制
     * @return void
     */
    copy(content) {
      //创建input标签
      var input = document.createElement("input");
      //将input的值设置为需要复制的内容
      input.value = content;
      //添加input标签
      document.body.appendChild(input);
      //选中input标签
      input.select();
      //执行复制
      document.execCommand("copy");
      //成功提示信息
      this.$message.success("复制成功!");
      //移除input标签
      document.body.removeChild(input);
    },
  },
  created() {
    this.orderId = this.$route.query.id;
    if(this.$route.params.type == 1){
      this.type = this.$route.params.type;
      this.orderId = this.$route.params.id;
      this.wxImg = this.$route.params.wxImg;
      this.from = this.$route.params.from;
    }

	if (this.type == 1) {
		let wxTimed = setInterval(async ()=>{
		    let res = await this.$api.getPayStatusApi({id:this.orderId,from:this.from})
		    if (res.data.order_status !== 0 && res.data.order_status !== 4) {
		      this.$message.success("支付成功！");
		      this.$router.push({ path: "/orderlist" });
		      clearInterval(wxTimed)
		    }
		},1500)
		
		this.$once('hook:beforeDestroy',()=>{
		  clearInterval(wxTimed)
		  wxTimed = undefined
		})
	}

    this.getOrderDetail();
  },
};
</script>
<style lang='scss'>
button {
  cursor: pointer;
}

section {
  padding-bottom: 80px;
}

.wx-box {
  .wx-info {
    margin: 25px 14px;

    .tips {
      line-height: 1.5;

      span {
        margin: 0 5px;
      }
    }

    .payment {
      margin-left: 330px;
    }
  }

  .wx-code {
    box-shadow: 0px 0px 8px 0px rgba(102, 102, 102, 0.35);
    padding: 61px 0 138px;
    align-items: flex-start;

    .code-box {
      border-right: $-solid-border;

      .title {
        font-size: 28px;
        margin-bottom: 5px;
      }

      .pay-code {
        width: 348px;
        height: 348px;
        margin-bottom: 5px;
      }

      .tips {
        img {
          width: 37px;
          height: 37px;
          margin-right: 10px;
        }
      }
    }

    .course-box {
      img {
        width: 303px;
      }
    }
  }
}

.line-box {
  padding-top: 25px;

  .title {
    width: 127px;
    height: 46px;
    background: #44aaea;
  }

  .line-content {
    box-shadow: 0px 0px 8px 0px rgba(102, 102, 102, 0.35);
    border-radius: 3px;
    padding: 25px 86px 65px;

    .payment {
      margin-bottom: 18px;
    }

    .flow-list {
      position: relative;
      left: -58px;

      .flow-item {
        padding-bottom: 36px;
        align-items: flex-start;
        position: relative;

        &::before {
          content: "";
          width: 3px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 14px;
          background-color: #e0f3ff;
        }

        &:last-child {
          margin-bottom: 0;

          &::before {
            display: none;
          }
        }

        .current {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: $-color-primary;
          margin-right: 28px;
          position: relative;
          z-index: 2;
        }
      }
    }

    .account-info {
      padding: 50px 30px 33px;
      background: #fafafa;
      display: inline-block;

      .info-title {
        top: 0;
        left: 50%;
        width: 180px;
        height: 30px;
        transform: translateX(-50%);
        background-image: url("~@/assets/title-bg.png");
        background-size: 180px 30px;
      }

      .info-list {
        padding-right: 84px;

        .info-item {
          margin-bottom: 20px;

          .item-name {
            width: 42px;
            position: relative;
            text-align: justify;
            margin-right: 20px;
            line-height: 0.3;

            &:before,
            &:after {
              content: "";
              padding-left: 100%;
              display: inline-block;
              height: 0;
            }
          }

          .item-copy {
            position: absolute;
            right: 30px;
            cursor: pointer;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .tips-box {
      position: relative;
      left: -58px;
      right: -58px;

      .tips-title {
        margin-bottom: 15px;
      }

      .info {
        line-height: 1.5;
      }
    }

    .back {
      padding: 0 50px;
      height: 48px;
      line-height: 48px;
      border-radius: 24px;
      margin-top: 60px;
    }
  }

}



.el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  button {
    border-radius: 5px;
    padding: 10px 20px;
  }
}

.el-upload:hover {
  border-color: inherit;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>